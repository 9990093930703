import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.ROUTER_BASE,
  // base: "dev/nordicminds/",
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue"),
      meta: {
        title: "Nordic Minds - Executive Search & Board Services",
      },
    },
    {
      path: "/candidate/",
      name: "candidate",
      component: () => import("./views/Candidate.vue"),
      meta: {
        title: "Nordic Minds - Candidate",
      },
    },
    {
      path: "/about/",
      name: "about",
      component: () => import("./views/about/About.vue"),
      meta: {
        title: "Nordic Minds - About",
      },
      redirect: "/about/doing-things-the-nordic-way",
      children: [
        {
          path: "doing-things-the-nordic-way",
          name: "Positioning",
          component: () => import("./views/about/Positioning.vue"),
          meta: {
            title: "Nordic Minds - Doing things the Nordic way",
          },
        },
        {
          path: "team",
          name: "team",
          component: () => import("./views/about/team/Team.vue"),
          meta: {
            title: "Nordic Minds - Team",
          },
          redirect: "/about/team/marcus-honkanen",
          children: [
            {
              path: "marcus-honkanen",
              name: "MarcusHonkanen",
              component: () => import("./views/about/team/MarcusHonkanen.vue"),
              meta: {
                title: "Nordic Minds - Marcus Honkanen",
              },
            },
            {
              path: "fanny-aberg",
              name: "FannyAberg",
              component: () => import("./views/about/team/FannyAberg.vue"),
              meta: {
                title: "Nordic Minds - Fanny Åberg",
              },
            },
            {
              path: "christian-fuchs",
              name: "ChristianFuchs",
              component: () => import("./views/about/team/ChristianFuchs.vue"),
              meta: {
                title: "Nordic Minds - Christian Fuchs",
              },
            },
            {
              path: "christian-tegethoff",
              name: "ChristianTegethoff",
              component: () =>
                import("./views/about/team/ChristianTegethoff.vue"),
              meta: {
                title: "Nordic Minds - Christian Tegethoff",
              },
            },
            {
              path: "maike-van-den-boom",
              name: "MaikeVanDenBoom",
              component: () =>
                import("./views/about/team/MaikeVanDenBoom.vue"),
              meta: {
                title: "Nordic Minds - Maike van den Boom",
              },
            },
            // {
            //   path: "christopher-grahn",
            //   name: "ChristopherGrahn",
            //   component: () =>
            //     import("./views/about/team/ChristopherGrahn.vue"),
            //   meta: {
            //     title: "Nordic Minds - Christopher Grahn",
            //   },
            // },
            {
              path: "jasmin-christensen",
              name: "JasminChristensen",
              component: () =>
                import("./views/about/team/JasminChristensen.vue"),
              meta: {
                title: "Nordic Minds - Jasmin Christensen-Matassa",
              },
            },
            {
              path: "lotta-veikonheimo",
              name: "LottaVeikonheimo",
              component: () => import("./views/about/team/LottaVeikonheimo.vue"),
              meta: {
                title: "Nordic Minds - Lotta Veikonheimo",
              },
            },
            {
              path: "petri-toivanen",
              name: "PetriToivanen",
              component: () => import("./views/about/team/PetriToivanen.vue"),
              meta: {
                title: "Nordic Minds - Petri S. Toivanen",
              },
            },
            {
              path: "christian-hoppe",
              name: "ChristianHoppe",
              component: () => import("./views/about/team/ChristianHoppe.vue"),
              meta: {
                title: "Nordic Minds - Christian Hoppe",
              },
            },
            {
              path: "anja-golumbeck",
              name: "AnjaGolumbeck",
              component: () => import("./views/about/team/AnjaGolumbeck.vue"),
              meta: {
                title: "Nordic Minds - Anja Golumbeck",
              },
            },
          ],
        },
        {
          path: "network",
          name: "network",
          component: () => import("./views/about/Network.vue"),
          meta: {
            title: "Nordic Minds - Network & Partner",
          },
        },
        {
          path: "advisory-board",
          name: "advisory-board",
          component: () => import("./views/about/advisoryBoard/AdvisoryBoard.vue"),
          meta: {
            title: "Nordic Minds - Advisory Board",
          },
          redirect: "/about/advisory-board/andreas-straarup",
          children: [
            {
              path: "andreas-straarup",
              name: "AndreasStraarup",
              component: () => import("./views/about/advisoryBoard/AndreasStraarup.vue"),
              meta: {
                title: "Nordic Minds - Andreas Straarup",
              },
            },
            {
              path: "brigitte-schnakenbourg",
              name: "BrigitteSchnakenbourg",
              component: () => import("./views/about/advisoryBoard/BrigitteSchnakenbourg.vue"),
              meta: {
                title: "Nordic Minds - Brigitte Schnakenbourg",
              },
            },
            {
              path: "outi-sortino",
              name: "OutiSortino",
              component: () => import("./views/about/advisoryBoard/OutiSortino.vue"),
              meta: {
                title: "Nordic Minds - Outi Sortino",
              },
            },
            {
              path: "anna-mindeloef",
              name: "AnnaMindeloef",
              component: () => import("./views/about/advisoryBoard/AnnaMindeloef.vue"),
              meta: {
                title: "Nordic Minds - Anna Mindelöf",
              },
            }
          ],
        },
      ],
    },
    {
      path: "/service",
      name: "service",
      component: () => import("./views/service/Service.vue"),
      meta: {
        title: "Nordic Minds - Service",
      },
      redirect: "/service/nordic-minds-search",
      children: [
        {
          path: "nordic-minds-search",
          name: "nordicMindsSearch",
          component: () => import("./views/service/NordicMindsSearch.vue"),
          meta: {
            title: "Nordic Minds - Nordic Minds Search",
          },
        },
        {
          path: "executive-search",
          name: "executiveSearch",
          component: () => import("./views/service/ExecutiveSearch.vue"),
          meta: {
            title: "Nordic Minds - Executive Search",
          },
        },
        {
          path: "board-services",
          name: "boardServices",
          component: () => import("./views/service/BoardServices.vue"),
          meta: {
            title: "Nordic Minds - Boards Services",
          },
        },
      ],
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("./views/Contact.vue"),
      meta: {
        title: "Nordic Minds - Contact",
      },
    },
    {
      path: "/futurehunt",
      name: "futurehunt",
      component: () => import("./views/FutureHunt.vue"),
      meta: {
        title: "FutureHunt",
      },
    },
    {
      path: "/thankyou",
      name: "thankyou",
      component: () => import("./views/Thankyou.vue"),
      meta: {
        title: "Nordic Minds - Thank You",
      },
    },
    {
      path: "/imprint",
      name: "imprint",
      component: () => import("./views/Imprint.vue"),
      meta: {
        title: "Nordic Minds - Imprint",
      },
    },
    {
      path: "/privacy",
      name: "privacy",
      component: () => import("./views/Privacy.vue"),
      meta: {
        title: "Nordic Minds - Privacy",
      },
    },
    // and finally the default route, when none of the above matches:
    { path: "*", component: () => import("./views/PageNotFound.vue") },
  ],
  scrollBehavior() {
    return window.scrollTo({ top: 1, behavior: "instant" }); // top: 1; Firefox Bug if 0;
  },
});
